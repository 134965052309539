import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {ButtonAllModule} from '@syncfusion/ej2-angular-buttons';
import {DialogAllModule} from '@syncfusion/ej2-angular-popups';

// test
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production }),
        ButtonAllModule, DialogAllModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
