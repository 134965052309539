import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PwaService} from './pwa.service';
import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import {Circle as CircleStyle, Fill, Icon, Stroke, Style} from 'ol/style';
import OSM from 'ol/source/OSM';
import * as olProj from 'ol/proj';
import {fromLonLat, toLonLat, transformExtent} from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import {Feature, MapBrowserEvent, Overlay} from 'ol';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import VectorSource from 'ol/source/Vector';
import {Extent} from 'ol/extent';
import Projection from 'ol/proj/Projection';
import {FeatureLike} from 'ol/Feature';
import {Geometry, Point, Polygon} from 'ol/geom';
import OSMXML from 'ol/format/OSMXML';
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import OverlayPositioning from 'ol/OverlayPositioning';
import {Control, defaults as defaultControls} from 'ol/control';
import {Subject} from 'rxjs';
import {Coordinate} from 'ol/coordinate';
import {debounceTime, throttleTime} from 'rxjs/operators';
import {capitalizeFirstLetter, isValidUrl} from './Utils';
import {OpenLayersConst, OpenlayersService} from './openlayers.service';
import {GeolocationService, PositionEvent} from './geolocation.service';
import {LocationSet} from './location-set';
import { DialogUtility } from '@syncfusion/ej2-popups';
import {Options} from 'ol/control/Control';
import {environment} from '../environments/environment.prod';

// import * as OverpassFrontend from 'overpass-frontend';

class RotateNorthControl extends Control {

    constructor(optOptions: Options) {
        // (opt_options);

        const options = optOptions || {};

        const button = document.createElement('button');
        button.innerHTML = 'N';

        const element = document.createElement('div');
        element.className = 'my-zoom rotate-north ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', this.handleRotateNorth.bind(this), false);
    }

    handleRotateNorth(): void {
        this.getMap().getView().animate({ rotation: 0, duration: 200 });
    }
}

const MarkerImgSize = 22;

const DefaultProjection = OpenLayersConst.WebMercatorProjection;
const HomeCoord = olProj.fromLonLat([11.5077422, 48.1342718]);

/** ID of the feature, representing our own location. */
const FEATUREID_CURRENTPOS = 'currentpos';

enum FeatureClass {
    WC = 'WC',
    Burger = 'Burger',
    FuelStation = 'FuelStation',
    ShoppingCentre = 'ShoppingCentre',
    Restaurant = 'Restaurant',
    Cafe = 'Cafe',
    Unknown = 'Unknown'
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

    title = 'restr00m-radar';
    production = environment.production;

    private map: Map;
    private view: View;
    private vectorLayer: VectorLayer;
    private polygonStyle: Style;
    private circleStyle: Style;
    private mypositionStyle: Style;
    private popup: Overlay;
    private popupEl: HTMLElement;
    private vectorSource: VectorSource<Geometry>;
    activeFeature: FeatureLike;

    private iconStyles: { [key: string]: Style } = {};

    private locationSet: LocationSet;
    private mapViewExtentTracker = new Subject<Extent>();

    @ViewChild('map') mapEl: ElementRef<HTMLElement>;
    @ViewChild('progress') progressEl: ElementRef<HTMLProgressElement>;
    @ViewChild('popupInfo') popupInfoEl: ElementRef<HTMLDivElement>;
    private positionSvg: string;
    private positionSvgEl: HTMLImageElement;

    constructor(
        private el: ElementRef<HTMLElement>,
        private httpClient: HttpClient,
        public pwa: PwaService,
        private geolocService: GeolocationService,
        private olSvc: OpenlayersService
    ) {
        this.pwa = pwa;
        this.locationSet = new LocationSet(this.httpClient, this.olSvc);
        this.mapViewExtentTracker.pipe(
            debounceTime(100), throttleTime(2000)
        ).subscribe(async (extent) => this.searchAmenities());
    }

     private async loadSvg(url: string, size: number = 24): Promise<HTMLImageElement> {
        const body = await this.httpClient.get(url, { responseType: 'text'}).toPromise();
        const sectionEl = document.createElement('section');
        sectionEl.innerHTML = body;

        const svgEl = sectionEl.querySelector<SVGElement>('svg');
        svgEl.setAttribute('width', `${size}px`);
        svgEl.setAttribute('height', `${size}px`);

        const positionSvg = btoa(unescape(encodeURIComponent(svgEl.outerHTML)));
        const positionSvgEl = new Image(size, size);
        positionSvgEl.src = 'data:image/svg+xml;base64,' + positionSvg;
        return positionSvgEl;
    }

    async installPwa(): Promise<void> {
        await this.pwa.promptEvent?.prompt();
    }

    async ngOnInit(): Promise<void> {
        this.popupEl = this.el.nativeElement.querySelector<HTMLElement>('.popup');
        this.iconStyles[FeatureClass.WC] = await this.createSvgIconStyle('/assets/svg/restroom.svg');
        this.iconStyles[FeatureClass.Burger] = await this.createSvgIconStyle('/assets/svg/cheeseburger.svg');
        this.iconStyles[FeatureClass.FuelStation] = await this.createSvgIconStyle('/assets/svg/gas-pump.svg');
        this.iconStyles[FeatureClass.ShoppingCentre] = await this.createSvgIconStyle('/assets/svg/bags-shopping.svg');
        this.iconStyles[FeatureClass.Restaurant] = await this.createSvgIconStyle('/assets/svg/utensils.svg');
        this.iconStyles[FeatureClass.Cafe] = await this.createSvgIconStyle('/assets/svg/coffee.svg');
        this.iconStyles[FeatureClass.Unknown] = await this.createSvgIconStyle('/assets/svg/question.svg');
    }

    private async createSvgIconStyle(url: string): Promise<Style> {
        return new Style({
            image: new Icon({
                img: await this.loadSvg(url, MarkerImgSize),
                imgSize: [MarkerImgSize, MarkerImgSize]
            })
        });
    }

    async ngAfterViewInit(): Promise<void> {
        setTimeout(this.initialize.bind(this, 100));
    }

    async initialize(): Promise<void> {
        this.polygonStyle = new Style({
            stroke: new Stroke({ color: 'blue', width: 3, }),
            fill: new Fill({ color: 'rgba(0, 0, 255, 0.1)', }),
        });
        this.circleStyle = new Style({
            image: new CircleStyle({
                radius: 16,
                stroke: new Stroke({ color: 'black', width: 3 }),
                fill: new Fill({ color: 'rgba(255, 255, 255, 0.7)', }),
            }),
        });
        this.mypositionStyle = new Style({
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({ color: 'red', width: 4 })
            }),
        });

        this.vectorSource = new VectorSource({
            features: [],
            format: new OSMXML(),
            loader: this.onBboxChange.bind(this), // this.amenitiesLoader.bind(this),
            strategy: bboxStrategy,
        });

        this.vectorLayer = new VectorLayer({
            source: this.vectorSource,
            style: this.styleLoader.bind(this)
        });

        this.popup = new Overlay({
            element: this.popupEl,
            positioning: OverlayPositioning.BOTTOM_CENTER,
            stopEvent: false,
            autoPan: true,
            autoPanAnimation: { duration: 250 },
            offset: [0, -10],
        });

        const currentPos = this.geolocService.lastKnown();
        this.view = new View({
            projection: DefaultProjection,
            center: fromLonLat(currentPos.coord),
            zoom: 15
        });
        this.geolocService.positions().subscribe(this.updateCurrentPosition.bind(this));
        this.geolocService.firstTracked().subscribe(this.movePositionToCenter.bind(this));

        this.map = new Map({
            controls: defaultControls({
                rotate: false,
                attribution: false,
                zoomOptions: { className: 'my-zoom' }
            }).extend([new RotateNorthControl({})]),
            target: this.mapEl.nativeElement,
            layers: [
                new TileLayer({ source: new OSM() }),
                this.vectorLayer
            ],
            overlays: [this.popup],
            view: this.view,
        });
        this.map.on('click', this.onFeatureClick.bind(this));
    }

    async goToCurrentPosition(): Promise<void> {
        const current = olProj.fromLonLat(this.geolocService.lastKnown().coord);
        this.view.animate({
            center: current,
            duration: 500,
        });
    }

    updateCurrentPosition(current: PositionEvent): void {
        const feature = this.vectorSource.getFeatureById(FEATUREID_CURRENTPOS);
        if (feature) {
            feature.setGeometry(new Point(fromLonLat(current.coord)));
        }
    }

    movePositionToCenter(position: PositionEvent): void {
        console.log('Moving center!!!');
        this.view.setCenter(fromLonLat(position.coord));
    }

    navigateToFeature(): void  {
        const url = this.createNavigationUrl(this.getFeatureCoordinates(this.activeFeature));
        const link = `<a href="${url}">Starte Navigation</a><br/>(${url}, ${navigator.platform}, ${navigator.userAgent})`;
        // DialogUtility.alert(link);
        window.open(url, '_blank');
    }

    async searchAmenities(): Promise<void> {
        try {
            this.progressEl.nativeElement.style.display = 'block';

            // get the area of interest from the map and make sure our current
            // set of locations covers them (will reload if not)
            const viewBbox = this.view.calculateExtent(this.map.getSize());
            const worldExtent = transformExtent(viewBbox, this.view.getProjection(), OpenLayersConst.WorldGeodeticSystem);
            const needsReload = this.locationSet.assertExtentCovered(worldExtent);

            if (needsReload) {
                // now load the features and exchange the current vector list of features
                const features = (await this.locationSet.loadAsFeatures(this.view.getProjection()))
                    .filter(this.isOfInterest.bind(this));
                this.vectorSource.clear();
                this.vectorSource.addFeatures(features);

                const mypos = this.geolocService.lastKnown().coord;
                const marker = new Feature(new Point(fromLonLat(mypos)));
                marker.setId(FEATUREID_CURRENTPOS);
                this.vectorSource.addFeature(marker);
            }
        } finally {
            this.progressEl.nativeElement.style.display = 'none';
        }
    }

    private onBboxChange(extent: Extent, resolution: number, projection: Projection): void {
        this.mapViewExtentTracker.next(extent);
    }

    async amenitiesLoader(extent: Extent, resolution: number, projection: Projection): Promise<void> {
        console.log(projection);
    }

    private isOfInterest(feature: Feature<Geometry>): boolean {
        const featureClass = this.determineFeatureClass(feature);
        const props = feature.getProperties();
        const name = props.name;
        const brand = props.brand;
        const hasToilets = props.toilets || props['toilets:access'] || props['toilets:wheelchar'];

        if (featureClass === FeatureClass.WC || hasToilets) {
            return true;
        } else if (featureClass === FeatureClass.Burger) {
            const wellKnown = /(mcdonald|burger\s*king|kentucky|subway)/i;
            return wellKnown.test(name) || wellKnown.test(brand);
        }
        return true;
    }

    findClosest(): void {
        const current = this.geolocService.lastKnown().coord;
        const closest = this.locationSet.findClosest(current);
        this.activateFeature(closest);
    }

    styleLoader(feature: FeatureLike, resolution: number): Style[] {
        const geometry = feature.getGeometry();
        if (feature.getId() === FEATUREID_CURRENTPOS) {
            return [this.mypositionStyle];
        } else if (geometry instanceof Polygon) {
            return [this.polygonStyle];
        } else {
            const featureClass = this.determineFeatureClass(feature);
            return [this.circleStyle, this.iconStyles[featureClass]];
        }
    }

    private normalizeFull(text: string): string {
        if (text == null)  return '';
        return text.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '').toLowerCase();
    }

    private determineFeatureClass(feature: FeatureLike): FeatureClass {
        const amenity = this.normalizeFull(feature.getProperties().amenity);
        const shop = this.normalizeFull(feature.getProperties().shop);
        if (/^toilet/.test(amenity)) {
            return FeatureClass.WC;
        } else if (/^fast_food/.test(amenity)) {
            return FeatureClass.Burger;
        } else if (/^fuel/.test(amenity)) {
            return FeatureClass.FuelStation;
        } else if (/^mall/.test(shop)) {
            return FeatureClass.ShoppingCentre;
        } else if (/^restaurant/.test(amenity)) {
            return FeatureClass.Restaurant;
        } else if (/^(cafe|coffee\s*shop)/.test(amenity)) {
            return FeatureClass.Cafe;
        }else {
            return FeatureClass.Unknown;
        }
    }

    onFeatureClick(event: MapBrowserEvent): void {
        const feature = this.map.getFeaturesAtPixel(event.pixel)[0];
        this.activateFeature(feature);
    }

    private activateFeature(feature: FeatureLike): void {
        if (feature) {
            const coordinate = this.getFeatureCoordinates(feature);
            this.popup.setPosition(coordinate);
            this.updatePopupFeatureInfo(feature);
        } else {
            this.popup.setPosition(undefined);
        }
        this.activeFeature = feature;
    }

    private updatePopupFeatureInfo(feature: FeatureLike): void {
        const el = this.popupInfoEl.nativeElement;
        if (feature instanceof Feature) {
            el.innerHTML = '';
            feature.getKeys()
                .filter(this.featureKeysForDisplay)
                .forEach(key => this.appendLabelAndText(el, key, this.getFeatureValue(feature, key)));

            const mypos = this.geolocService.lastKnown().coord;
            const fpos = toLonLat(this.getFeatureCoordinates(feature));
            const dist = this.olSvc.getDistanceFromCoordInKm(mypos, fpos);
            this.appendLabelAndText(el, 'Entfernung', this.distAsText(dist));
        } else {
            this.appendLabelAndText(el, 'Feature type', feature.constructor.name);
        }
    }

    private featureKeysForDisplay(key: string): boolean {
        const discard = ['geometry', 'xamenity', 'fixme'];
        return discard.indexOf(key.toLowerCase()) < 0;
    }

    private getFeatureCoordinates(feature: FeatureLike): Coordinate {
        return (feature.getGeometry() as Point).getCoordinates();
    }

    private appendLabelAndText(parent: HTMLElement, label: string, text: string, isHtml: boolean = false): void {
        const labelEl = document.createElement('div');
        const valueEl = document.createElement('div');
        labelEl.innerText = capitalizeFirstLetter(label);
        labelEl.classList.add('label');
        valueEl.classList.add('value');
        if (isHtml) {
            valueEl.innerHTML = text;
        } else if (isValidUrl(text)) {
            valueEl.innerHTML = `<a href=${text} target="_blank">${text}</a>`;
        } else {
            valueEl.innerText = text;
        }
        parent.append(labelEl, valueEl);
    }

    private getFeatureValue(feature: Feature, key: string): string {
        const value = feature.get(key);
        if (value == null) {
            return '-';
        } else if (typeof (value) === 'object') {
            return value.constructor.name;
        } else {
            return value.toString();
        }
    }

    // https://stackoverflow.com/questions/18739436/how-to-create-a-link-for-all-mobile-devices-that-opens-google-maps-with-a-route
    createNavigationUrl(coordinate: Coordinate): string {
        const [lng, lat] = toLonLat(coordinate, DefaultProjection);
        const params = new URLSearchParams();
        params.append('api', '1');
        params.append('travelmode', 'walking');
        params.append('layer', 'traffic');
        params.append('destination', `${lat},${lng}`);

        const platform = navigator.platform;
        const isApple = /i(Phone|Pod|Pad)/i.test(platform);
        const isAndroid = /Android/i.test(platform);

        if (isAndroid) {
            return `google.navigation:q=${lat},${lng}&mode=w`;
        } else {
            const protocol = isApple ? 'maps' : 'https';
            return `${protocol}://www.google.com/maps/dir/?${params.toString()}`;
        }
    }

    private distAsText(d: number): string {
        if (d < 1.0) {
            const m = Math.round(d * 100.0) * 10.0;
            return `${m}m`.replace(/\.\d*/, '');
        } else if (d < 10.) {
            const m = Math.round(d * 10.0) / 10.0;
            return `${m}km`;
        } else {
            const m = Math.round(d);
            return `${m}km`;
        }
    }

    async showBbox(): Promise<void> {
        const ext = this.view.calculateExtent();
        const inLonLat = transformExtent(ext, OpenLayersConst.WebMercatorProjection, OpenLayersConst.WorldGeodeticSystem);
        const inLatLon = this.olSvc.swapLonLat(inLonLat);
        const bbox = inLatLon.join(',');
        await navigator.clipboard.writeText(bbox);
        DialogUtility.alert(bbox);
    }
}
