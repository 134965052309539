import { Injectable } from '@angular/core';
import {Observable, Observer, of, ReplaySubject, Subject} from 'rxjs';
import {Coordinate} from 'ol/coordinate';
import {Geolocation} from 'ol';
import {OpenLayersConst} from './openlayers.service';
import BaseEvent from 'ol/events/Event';
import Projection from 'ol/proj/Projection';
import {ProjectionLike} from 'ol/proj';
import {debounceTime, skip, take} from 'rxjs/operators';

export interface PositionEvent {
    accuracy: number;
    coord: Coordinate;
    projection: ProjectionLike;
}

const FallbackPosition: PositionEvent = {
    coord: [11.5732598, 48.1373968],
    accuracy: 100,
    projection: OpenLayersConst.WorldGeodeticSystem
};

@Injectable({
    providedIn: 'root'
})
export class GeolocationService {

    /** Source of positions. */
    private position = new Subject<PositionEvent>();

    /** Last known position, persistent to allow a quick guess on start. */
    private lastKnownPosition: PositionEvent;

    /** First tracked position. Useful, to shift the view only once, when the first ever reported position comes in. */
    private firstTrackedPostion = new ReplaySubject<PositionEvent>();

    /** Gelocation helper from open layers. */
    private geolocation: Geolocation;

    /** Flag to indicate if a position has been reported by the Geolocation API. */
    private hasTrackedPosition = false;

    constructor() {
        this.geolocation = new Geolocation({
            // enableHighAccuracy must be set to true to have the heading value.
            tracking: true,
            trackingOptions: { enableHighAccuracy: true },
            projection: OpenLayersConst.WorldGeodeticSystem,
        });

        this.geolocation.on('change:position', (evt: BaseEvent) => {
            const position = {
                coord: this.geolocation.getPosition(),
                accuracy: this.geolocation.getAccuracy(),
                projection: OpenLayersConst.WorldGeodeticSystem
            };
            console.log('new position', position);

            this.setLastKnownPosition(position.coord, position.accuracy);
            this.position.next(position);

            if (!this.hasTrackedPosition) {
                this.hasTrackedPosition = true;
                this.firstTrackedPostion.next(position);
            }
        });
    }

    lastKnown(): PositionEvent {
        if (!this.lastKnownPosition) {
            const json = localStorage.getItem('lastKnownPosition');
            if (json) {
                this.lastKnownPosition = JSON.parse(json);
            } else {
                this.setLastKnownPosition(FallbackPosition.coord, FallbackPosition.accuracy);
            }
        }
        return this.lastKnownPosition;
    }

    private setLastKnownPosition(coord: Coordinate, accuracy: number): void {
        this.lastKnownPosition = { accuracy, coord, projection: OpenLayersConst.WorldGeodeticSystem };
        localStorage.setItem('lastKnownPosition', JSON.stringify(this.lastKnownPosition));
    }

    positions(): Observable<PositionEvent> {
        return this.position.pipe(debounceTime(5000));
    }

    nextPosition(): Observable<PositionEvent> {
        return this.position.pipe(take(1));
    }

    lastPosition(): Observable<PositionEvent> {
        return of(this.lastKnown());
    }

    firstTracked(): Observable<PositionEvent> {
        return this.firstTrackedPostion;
    }
}
