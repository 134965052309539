import {containsExtent, Extent, getCenter} from 'ol/extent';
import {FeatureLike} from 'ol/Feature';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {fromLonLat, toLonLat, transformExtent} from 'ol/proj';
import OSMXML from 'ol/format/OSMXML';
import {OpenlayersService} from './openlayers.service';
import {Feature} from 'ol';
import {Geometry, Point, Polygon} from 'ol/geom';
import Projection from 'ol/proj/Projection';
import {coordinates} from 'ol/geom/flat/reverse';
import {Coordinate} from 'ol/coordinate';

/**
 * All extents and coordinates need to be in Long/Lang format, as this service is an adapter
 * to the open street map services, which use regular lat/long values.
 */
export class LocationSet {

    /** Extent from which we have the locations. */
    private extent: Extent;

    /** Minimum extent size, to get enough locations. */
    public minExtentSizeKm = 10;

    /** All features, relevant to the set. */
    private locations: { lonLat: Coordinate, feature: FeatureLike }[] = [];

    constructor(private httpClient: HttpClient, private olSvc: OpenlayersService) {
    }

    assertExtentCovered(newExtent: Extent): boolean {
        if (this.extent && containsExtent(this.extent, newExtent)) {
            return false;
        } else {
            const top    = Math.min(newExtent[0], newExtent[2]);
            const bottom = Math.max(newExtent[0], newExtent[2]);
            const left   = Math.min(newExtent[1], newExtent[3]);
            const right  = Math.max(newExtent[1], newExtent[3]);
            const tl = [top, left];
            const tr = [top, right];
            const bl = [bottom, left];
            const horSize = this.olSvc.getDistanceFromCoordInKm(tl, tr);
            const verSize = this.olSvc.getDistanceFromCoordInKm(tl, bl);
            const minSize = Math.min(horSize, verSize);

            if (minSize < this.minExtentSizeKm) {
                this.extent = this.olSvc.makeExtent(getCenter(newExtent), this.minExtentSizeKm);
            } else {
                this.extent = newExtent;
            }
            return true;
        }
    }

    async loadAsFeatures(projection: Projection): Promise<Feature<Geometry>[]> {
        const x = this.extent;
        const inLatLong = [x[1], Math.max(x[0], -180), x[3], Math.min(x[2], 180)];
        const bbox = inLatLong.join(',');

        const query = `
            [out:xml][timeout:25];
            (
              node["amenity"="toilets"](${bbox});
              node["toilets"="yes"](${bbox});
              node["amenity"="fast_food"](${bbox});
              way["amenity"="toilets"](${bbox});
              relation["amenity"="toilets"](${bbox});
            );
            out center;
            out body;
            >;
            out skel qt;
        `;
        const formData = new FormData();
        formData.append('data', query);

        const body = new URLSearchParams();
        body.set('data', query);

        const headers = new HttpHeaders({
            enctype: 'multipart/form-data',
            'content-type': 'application/x-www-form-urlencoded'
        });
        const url = 'https://overpass-api.de/api/interpreter';
        const result = await this.httpClient.post(url, body.toString(), {observe: 'body', responseType: 'text', headers}).toPromise();

        const features = new OSMXML()
            .readFeatures(result, { featureProjection: projection })
            .map(f => this.asCenterPointFeature(f));

        this.locations = features.map(f => ({
            lonLat: toLonLat(this.getFeatureCoordinates(f), projection),
            feature: f
        }));

        return features;
    }

    private asCenterPointFeature(feature: Feature<Geometry>): Feature<Geometry> {
        const geometry = feature.getGeometry();
        if (geometry instanceof Polygon) {
            feature.set(feature.getGeometryName(), geometry.getInteriorPoint());
        }
        return feature;
    }

    private getFeatureCoordinates(feature: FeatureLike): Coordinate {
            return (feature.getGeometry() as Point).getCoordinates();
    }

    findClosest(coord: Coordinate): FeatureLike {
        let minDist = Number.POSITIVE_INFINITY;
        let result: FeatureLike;
        this.locations.forEach(l => {
            const dist = this.olSvc.getDistanceFromCoordInKm(coord, l.lonLat);
            if (dist < minDist) {
                minDist = dist;
                result = l.feature;
            }
        });
        return result;
    }
}
