<div class="main layout-vbox" style="grid-template-rows: auto 1fr; height: 100%; position: relative">
    <div class="header-pane layout-hbox">
        <div style="display: flex; flex-direction: row; align-items: center">
            <img src="assets/svg/eEUBuBKoE5.svg"
                 (click)="dialog.show()"
                 width="45" height="45" style="margin-right: 10px; border: 2px solid black; border-radius: 22px;">
            <h3>00-Radar</h3>
        </div>
        <div style="width: 100%">&nbsp;</div>
        <button ejs-button [disabled]="!activeFeature" iconCss="fas fa-running" (click)="navigateToFeature()"></button>
        <button ejs-button iconCss="fas fa-search-location" (click)="findClosest()"></button>
        <button ejs-button *ngIf="pwa.promptEvent" iconCss="fas fa-plus-circle" (click)="installPwa()"></button>
        <button ejs-button iconCss="fas fa-crosshairs" (click)="goToCurrentPosition()"></button>
        <button ejs-button *ngIf="!production" iconCss="far fa-rectangle-portrait" (click)="showBbox()"></button>
    </div>

    <div class="view-pane">
        <div #map class="map">
            <div id="popup" class="popup">
                <div #popupInfo class="info-popup layout-vbox" style="">
                    <div>Distance</div><div>300m</div>
                    <div>Kosten</div><div>0,70 €</div>
                </div>
            </div>
        </div>
    </div>
    <progress #progress class="progress">
    </progress>
</div>
<ejs-dialog #dialog class="ack-dialog" width="250px" [visible]="false" (beforeOpen)="dialog.refresh()"
            [animationSettings]="{ effect: 'None' }"
            [showCloseIcon]="false">
    <ng-template #content>
        <div class="layout-vbox ack-dialog-content">
            <div class="label">Made With</div>
            <div class="value">OpenLayer<br>OpenStreetMap<br>Angular</div>
            <div class="label">Idee</div>
            <div class="value">Alexander Heim<br>Amelie Heim<br>Magdalena Rettner</div>
            <div class="label">Entwicklung</div>
            <div class="value">Alexander Heim</div>
            <div class="label">Copyright</div>
            <div class="value">Heim · Rettner<br>&copy; 2020</div>
        </div>
    </ng-template>
    <ng-template #footerTemplate>
        <div style="display: flex; justify-content: center">
            <button ejs-button (click)="dialog.hide()">OK</button>
        </div>
    </ng-template>
</ejs-dialog>

