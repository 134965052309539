import {Coordinate} from 'ol/coordinate';

export function isValidUrl(text): boolean {
    try {
        const url = new URL(text);
        return true;
    } catch (_) {
        return false;
    }
}

export function capitalizeFirstLetter(text): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
}
