import { Injectable } from '@angular/core';
import {Coordinate} from 'ol/coordinate';
import {Point} from 'ol/geom';
import {Extent, } from 'ol/extent';
import {transformExtent} from 'ol/proj';

export const OpenLayersConst = {
    WebMercatorProjection: 'EPSG:3857',
    WorldGeodeticSystem: 'EPSG:4326',
};

@Injectable({
    providedIn: 'root'
})
export class OpenlayersService {

    constructor() { }

    getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // Radius of the earth in km
        const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = this.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }

    getDistanceFromCoordInKm(coord1: Coordinate, coord2: Coordinate): number {
        return this.getDistanceFromLatLonInKm(coord1[1], coord1[0], coord2[1], coord2[0]);
    }

    deg2rad(deg: number): number { return deg * (Math.PI / 180); }
    rad2deg(rad: number): number { return rad * (180 / Math.PI); }

    // https://stackoverflow.com/questions/2637023/how-to-calculate-the-latlng-of-a-point-a-certain-distance-away-from-another
    destinationPointFromLatLonInKm(lat: number, lon: number, brng: number, dist: number): [number, number] {
        dist = dist / 6371.0;
        brng = this.deg2rad(brng);

        const lat1 = this.deg2rad(lat);
        const lon1 = this.deg2rad(lon);

        const lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) +
            Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

        const lon2 = lon1 + Math.atan2(
            Math.sin(brng) * Math.sin(dist) * Math.cos(lat1),
            Math.cos(dist) - Math.sin(lat1) * Math.sin(lat2)
        );

        if (isNaN(lat2) || isNaN(lon2)) {
            return null;
        }

        return [this.rad2deg(lat2), this.rad2deg(lon2)];
    }

    destinationPointFromCoordInKm(coord: Coordinate, brng: number, dist: number): Coordinate {
        const latlong = this.destinationPointFromLatLonInKm(coord[1], coord[0], brng, dist);
        return [latlong[1], latlong[0]];
    }

    makeExtent(center: Point | Coordinate, squareLength: number): Extent {
        const p = (center instanceof Point) ? center.getCoordinates() : center;
        const halfDiag = Math.SQRT1_2 * squareLength;
        const p1 = this.destinationPointFromCoordInKm(p, 225, halfDiag);
        const p2 = this.destinationPointFromCoordInKm(p, 45, halfDiag);
        return [p1[0], p1[1], p2[0], p2[1]];
    }

    swapLonLat(coordOrExt: Coordinate): Coordinate {
        const pairs = coordOrExt.length / 2;
        const result = [];
        for (let i = 0; i < pairs; i++) {
            result[2 * i] = coordOrExt[2 * i + 1];
            result[2 * i + 1] = coordOrExt[2 * i];
        }
        return result;
    }
}
